
import { defineComponent, onMounted, ref } from "vue";
import { useForm, Field, Form } from "vee-validate";
import { object, string, array, number } from "yup";
import { ElMessage } from "element-plus";
import Places from "./crafted/widgets/Places.vue";
import Tagify from "@yaireo/tagify";
import membersApi from "@/core/services/MembersApi";
import institutionApi from "@/core/services/InstitutionApi";
import proForm from "@/core/services/ProForm";
import { useRoute } from "vue-router";
import axios from "axios";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        if (file === undefined) {
            return resolve(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }
    });
}

export default defineComponent({
    name: "builder",
    components: {
        Field,
        Form,
        Places,
    },
    data() {
        return {
            placesKey: 0,
            stepTitles: ["About your institution 1/4", "Contact 2/4", "Facilities 3/4", "Picture & benefits 4/4"],
            bgImgs: ["pro1.png", "pro2.png", "pro3.png", "pro4.png", "pro5.png"],
            stepIndex: 0,
            percentage: 25,
            creatingInstitution: false,
            food_styles: [] as any,
            credit_cards: [] as any,
            types: [] as any,
            remoteInstitutions: [] as any,
            canTagify: false,
            foodStylesValue: "",
            creditCardsValue: "",
            pictureName: "",
            displayConfirmation: false,
            institutionQuery: "",

            // Form Values
            formValues: {
                institution: {
                    name: "",
                    institution_type: "",
                    speciality: "",
                    restaurant_amount: "",
                    rooms_amount: "",
                    seats_amount: "",
                    stars: "",
                    food_styles: [],
                    credit_cards: [],
                    picture: "" as any,
                    benefits: "",
                    address: {
                        address: "",
                        country: "",
                        state: "",
                        city: "",
                        postal_code: "",
                    },
                },
                contact_pro: {
                    phone: "",
                    mobile: "",
                    email: "",
                    website: "",
                },
            },
        };
    },
    setup() {
        const route = useRoute();
        const proFormSchemaStep1 = object().shape({
            institution: string().required().label("Institution").nullable(),
        });

        const proFormSchemaStep1Creating = object().shape({
            institution: string().required().label("Institution").nullable(),
            address: string().required().label("Address").nullable(),
            country: string().required().label("Country").nullable(),
            state: string().required().label("State").nullable(),
            city: string().required().label("City").nullable(),
            postalCode: string().required().label("Postal code").nullable(),
        });

        const proFormSchemaStep2 = object().shape({
            phone: string().required().label("Phone").nullable(),
            mobile: string().required().label("Mobile").nullable(),
            email: string().required().label("Email").nullable(),
        });

        const proFormSchemaStep3 = object().shape({
            type: string().required().label("Establishment type").nullable(),
            //foodStyles: array(number()).required().label("Food styles").nullable(),
            speciality: string().required().label("Speciality").nullable(),
            restaurantsNb: number().required().typeError("Restaurants number is invalid").nullable(),
            stars: number().required().typeError("Stars number is invalid").nullable(),
            roomsNb: number().required().typeError("Rooms number is invalid").nullable(),
            seatsNb: number().required().typeError("Seats number is invalid").nullable(),
            //creditCards: array(number()).required().label("Credit cards").nullable(),
        });

        return { proFormSchemaStep1, proFormSchemaStep1Creating, proFormSchemaStep2, proFormSchemaStep3, route };
    },
    mounted() {
        this.getFields();
        this.placesKey++;
    },
    updated() {
        if (this.canTagify || this.stepIndex === 2) {
            var foodStyles = document.querySelector("#foodStyles");
            var creditCards = document.querySelector("#creditCards");

            new Tagify(foodStyles, {
                whitelist: this.food_styles.map((item) => {
                    return { value: item.label, id: item.id };
                }),
                dropdown: {
                    classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
                    enabled: 0, // <- show suggestions on focus
                    closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
                },
            });
            new Tagify(creditCards, {
                whitelist: this.credit_cards.map((item) => {
                    return { value: item.label, id: item.id };
                }),
                dropdown: {
                    classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
                    enabled: 0, // <- show suggestions on focus
                    closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
                },
            });
            this.canTagify = false;
        }
    },
    methods: {
        querySearchAsyncInstitution(queryString, cb) {
            this.loadRemoteInstitutions(queryString).then(() => {
                cb(this.remoteInstitutions);
            });
        },
        handleSelect(item: any, input: any) {
            if (input === "institution") {
                console.log("instiiiiit", item);
                // var institStreet = document.getElementById("autocomplete_memberInstitutionStreet") as any;
                // institStreet.value = item.address.street ? item.address.street : "";

                // this.institution.name = this.institutionQuery = item.name;
                // this.institution.id = item.id;
                // this.memberInstitution.state = item.address.state ? item.address.state : "";
                // this.memberInstitution.country = item.address.country ? item.address.country : "";
                // this.memberInstitution.city = item.address.city ? item.address.city : "";
                // this.memberInstitution.zip = item.address.zip ? item.address.zip : "";
                // this.institution.type = item.institution_type ? item.institution_type : "";
                // this.institution.speciality = item.speciality ? item.speciality : "";
                // this.institution.stars = item.stars ? item.stars : "";
                // this.institution.restaurant_nb = item.restaurant_amount ? item.restaurant_amount : "";
                // this.institution.plaqueDisplayed = item.chaine_plaque_displayed ? true : false;
                // this.institution.seats_nb = item.seats_amount ? item.seats_amount : "";
                // this.institution.benefits = item.benefits ? item.benefits : "";
                // this.institution.picture = item.picture ? item.picture : "";
                // this.institution.foodStyles = item.food_styles ? item.food_styles : "";
                // this.institution.creditCards = item.credit_cards ? item.credit_cards : "";
                // this.institution.foodStyles = item.food_styles.map((item: any) => {
                //     return item.id;
                // });
                // this.institution.creditCards = item.credit_cards.map((item: any) => {
                //     return item.id;
                // });
            }
            this.remoteInstitutions = [];
        },
        loadRemoteInstitutions(query) {
            return new Promise((resolve, reject) => {
                if (query) {
                    if (query !== "" && query.length >= 2) {
                        this.remoteInstitutions = [];
                        institutionApi.listRemote({ query: query }).then((response) => {
                            if (response.success) {
                                response.data.map((remoteInstitution) => {
                                    this.remoteInstitutions.push({
                                        ...remoteInstitution,
                                        name: remoteInstitution.name,
                                        address: {
                                            country: remoteInstitution.address.country,
                                            state: remoteInstitution.address.state,
                                            city: remoteInstitution.address.city,
                                            street: remoteInstitution.address.address,
                                            zip: remoteInstitution.address.postal_code,
                                        },
                                        id: remoteInstitution.id,
                                    });
                                });
                                resolve("");
                            } else {
                                reject("");
                            }
                        });
                    } else {
                        this.remoteInstitutions = [];
                        resolve("");
                    }
                }
            });
        },
        redirectToChaineNews() {
            location.href = "https://www.chainedesrotisseurs.com/";
        },
        sendForm() {
            parseInt(this.formValues.institution.restaurant_amount);
            parseInt(this.formValues.institution.rooms_amount);
            parseInt(this.formValues.institution.seats_amount);
            delete this.formValues.institution.picture;
            const payload = { token: this.route.params.token, ...this.formValues };

            membersApi.setInstitution(payload).then((res) => {
                if (res.status === 200) {
                    const fileInput = document.getElementById("institPic") as any;
                    const file = fileInput?.files[0];
                    const fileInstance = new File([file], file.name);
                    const formData = new FormData();

                    formData.append("file", fileInstance);
                    axios
                        .post("/api/v1/institution/" + res.data.id + "/upload-image/", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(() => {
                            this.nextStep();
                        })
                        .catch((error: any) => {
                            console.log(error);
                        });
                } else {
                    ElMessage({
                        type: "error",
                        message: "Unable to create ProMemForm. Please check all the fields across the tabs.",
                    });
                }
            });
        },
        getFields() {
            proForm.getInstitutionTypes({ p: 1, presult: 9999 }).then((res) => {
                this.types = res.data.object_list;
            });
            proForm.getFoodStyles({ p: 1, presult: 9999 }).then((res) => {
                this.food_styles = res.data.object_list;
            });
            proForm.getCreditCards({ p: 1, presult: 9999 }).then((res) => {
                this.credit_cards = res.data.object_list;
            });
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            const maxSizeInMB = 10;
            const fileSizeInMB = selectedImage.size / (1024 * 1024);

            if (fileSizeInMB > maxSizeInMB) {
                ElMessage({
                    type: "error",
                    message: "Image size must be less than 10MB",
                });
                return;
            }

            this.pictureName = e.target.files[0].name;
            getBase64(selectedImage).then((base64Document: any) => {
                this.formValues.institution.picture = base64Document;
            });
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    this.formValues.institution.picture = ev.target.result as string;
                }
            };
            reader.readAsDataURL(selectedImage);
        },
        removeImage() {
            const input = document.getElementById("institPic") as any;
            if (input) input.value = "";

            this.formValues.institution.picture = "";
            this.pictureName = "";
        },
        testSchema(values: any, step: string, action?: string) {
            if (step === "step1") {
                if (action === "selecting") {
                    this.proFormSchemaStep1.isValid(values).then((valid) => {
                        if (valid) {
                            this.nextStep();
                        } else {
                            ElMessage({
                                type: "error",
                                message: "Please fill in all the required fields.",
                            });
                        }
                    });
                } else if (action === "creating") {
                    this.proFormSchemaStep1Creating.isValid(values).then((valid) => {
                        if (valid) {
                            this.nextStep();
                        } else {
                            ElMessage({
                                type: "error",
                                message: "Please fill in all the required fields.",
                            });
                        }
                    });
                }
            } else if (step === "step2") {
                this.proFormSchemaStep2.isValid(values).then((valid) => {
                    if (valid) {
                        this.nextStep();
                    } else {
                        ElMessage({
                            type: "error",
                            message: "Please fill in all the required fields.",
                        });
                    }
                });
            } else if (step === "step3") {
                this.proFormSchemaStep3.isValid(values).then((valid) => {
                    if (valid) {
                        this.nextStep();
                    } else {
                        ElMessage({
                            type: "error",
                            message: "Please fill in all the required fields.",
                        });
                    }
                });
            }
        },
        nextStep() {
            if (this.percentage < 100) {
                this.stepIndex++;
                this.percentage += 25;

                if (this.stepIndex === 2) this.canTagify = true;
            } else {
                this.displayConfirmation = true;
                this.stepIndex++;
            }
        },
        prevStep() {
            if (this.percentage > 25) {
                this.stepIndex--;
                this.percentage -= 25;
            }
        },
        setPlaceInfo(event: any, scope?: string) {
            if (scope === "institution") {
                this.formValues.institution.name = event.name;
            }
            this.formValues.institution.address.address = event.address;
            this.formValues.institution.address.country = event.country;
            this.formValues.institution.address.state = event.state;
            this.formValues.institution.address.city = event.city;
            this.formValues.institution.address.postal_code = event.postalCode;
        },
        formatTags(e, tagifyInput) {
            var localFoodTags = [] as any;
            var localCreditTags = [] as any;
            if (e.target.value[0] === "[") {
                var parsedJson = JSON.parse(e.target.value);
                if (tagifyInput === "food_styles") {
                    this.foodStylesValue = "";
                    parsedJson.map((item: any) => {
                        localFoodTags.push(item.id);
                        if (this.foodStylesValue === "") this.foodStylesValue = item.value;
                        else this.foodStylesValue = this.foodStylesValue + ", " + item.value;
                    });
                    this.formValues.institution.food_styles = [];
                    this.formValues.institution.food_styles = localFoodTags;
                } else if (tagifyInput === "credit_cards") {
                    this.creditCardsValue = "";
                    parsedJson.map((item: any) => {
                        localCreditTags.push(item.id);
                        if (this.creditCardsValue === "") this.creditCardsValue = item.value;
                        else this.creditCardsValue = this.creditCardsValue + ", " + item.value;
                    });
                    this.formValues.institution.credit_cards = [];
                    this.formValues.institution.credit_cards = localCreditTags;
                }
            }
        },
    },
});
