import ApiService from "./ApiService";

const GET_INSTITUTION_TYPES = "api/v1/institution-type/list/";
const GET_FOOD_STYLES = "api/v1/food-style/list/";
const GET_CREDIT_CARDS = "api/v1/credit-card/list/";

export default {
    getInstitutionTypes(payload) {
        return ApiService.post(GET_INSTITUTION_TYPES, payload).then(response => {
            return response.data;
        });
    },
    getFoodStyles(payload) {
        return ApiService.post(GET_FOOD_STYLES, payload).then(response => {
            return response.data;
        });
    },
    getCreditCards(payload) {
        return ApiService.post(GET_CREDIT_CARDS, payload).then(response => {
            return response.data;
        });
    }
};